import React, { Component } from 'react';
import './TimesheetEntries.scss';
import { withCookies } from 'react-cookie';
import axios from 'axios';
import TableRow from "../../components/tableRow/TableRow";
import {
    UncontrolledDropdown,
    DropdownToggle,
    DropdownMenu,
    DropdownItem } from 'reactstrap';
import Preloader from "../../components/preloader/Preloader";
import confirm from 'reactstrap-confirm';
import { toast } from 'react-toastify';
import AddTimesheet from "../../components/addTimesheet/AddTimesheet";
import AppContext from "../../context/index";
import { CSVLink, CSVDownload } from "react-csv";
import userStore from "../../stores/user";
import DatePicker from "react-datepicker";

class TimesheetEntries extends Component {
    constructor(props) {
        super(props);

        this.state = {
            loading: false,
            tsEntries: [],
            totalHours: 0,
            showAddTimesheet: false,
            userRole: -1,
            editTEId: -1,
            ts_by_activity: [],
            filtersDevId: -1,
            filtersActivityId: -1,
            filtersStartDate: new Date(new Date().getFullYear(), new Date().getMonth(), 1),
            filtersEndDate: new Date(),
        };
    }

    componentDidMount() {
        const { cookies } = this.props;

        this.setState({
            userRole: cookies.get('userRole')
        });

        this.getTimesheetEntries();
    }

    getTimesheetEntries = () => {
        const {cookies} = this.props;

        this.setState({
            loading: true
        });

        axios.get(process.env.REACT_APP_API_URL + 'ts-by-projectId/' + this.props.projectId, {
            params: {
                dev: (this.state.filtersDevId !== -1 && this.props.resources[this.state.filtersDevId]) ? this.props.resources[this.state.filtersDevId].user_id : null,
                activity: (this.state.filtersActivityId !== -1 && this.state.ts_by_activity[this.state.filtersActivityId]) ? this.state.ts_by_activity[this.state.filtersActivityId].activity_type_id : null,
                startDate: this.state.filtersStartDate,
                endDate: this.state.filtersEndDate
            },
            headers: {
                'Authorization': 'Bearer ' + cookies.get('authToken')
            }
        })
        .then(res => {

            let auxHours = 0;
            for(let i in res.data.data) {
                auxHours += res.data.data[i].hours;
            }

            this.setState({
                tsEntries: res.data.data,
                loading: false,
                showAddTimesheet: false,
                editTEId: -1,
                totalHours: auxHours,
                ts_by_activity: Object.keys(this.state.ts_by_activity).length < 1 ? res.data.data.filter((obj, pos, arr) => {
                    return arr.map(mapObj => mapObj.activity_type_id).indexOf(obj.activity_type_id) === pos;
                }) : this.state.ts_by_activity
            });

        })
        .catch(err => {
            if(err && err.response && err.response.status === 401){
                //redirect to login
                this.context.redirectToLogin();
            } else {
                this.setState({
                    loading: false
                })
            }
        })
    }

    openModal = () => {
        this.setState({
            showAddTimesheet: true
        });
    }

    closeModal = refreshList => {
        this.setState({
            showAddTimesheet: false,
            editTEId: -1
        });
        if(refreshList) {
            this.getTimesheetEntries();
        }
    }

    editTSEntry = (teId) => {
        this.setState({
            editTEId: teId,
            showAddTimesheet: true
        })
    }

    deleteTSEntry = async (teId) => {
        const { cookies } = this.props;
        let result = await confirm({
            title: 'Are you sure?',
            message: "This action is irreversible",
            confirmText: "DELETE",
            confirmColor: "primary",
            cancelColor: "text-danger"
        });

        if (result) {
            this.setState({
                loading: true
            })

            axios.delete(process.env.REACT_APP_API_URL + 'ts/' + teId, {
                headers: {
                    'Authorization': 'Bearer ' + cookies.get('authToken')
                }
            })
            .then(res => {
                this.getTimesheetEntries();
                toast.success("Timesheet entry has been deleted.");
            })
            .catch(err => {
                if(err && err.response && err.response.status === 401){
                    //redirect to login
                    this.context.redirectToLogin();
                } else {
                    this.setState({
                        loading: false
                    })
                }
                toast.error("An error occured, please try again later.");
            })
        }
    };

    filtersChanged = (filterId, filterValue) => {

        if(filterId === 'filtersStartDate'){
            if(filterValue > this.state.filtersEndDate){
                this.state.filtersEndDate = filterValue;
            }
        }

        this.setState(
          {
              [filterId]: filterValue
          },
          () => {
              this.getTimesheetEntries();
          });
    };

    render() {

        let csvData = [];
        csvData.push(["User", "Date", "Hours", "Activity", "Extra", "QA", "OT", "Details"])

        for(let i in this.state.tsEntries) {
            csvData.push([
                this.state.tsEntries[i].user,
                this.state.tsEntries[i].manual_date,
                this.state.tsEntries[i].hours,
                this.state.tsEntries[i].activity_name,
                this.state.tsEntries[i].extra_request ? 'YES' : 'NO',
                this.state.tsEntries[i].qa_issue ? 'YES' : 'NO',
                this.state.tsEntries[i].overtime ? 'YES' : 'NO',
                this.state.tsEntries[i].details
            ]);
        }


        return (
            <div className="project-subsection">
                { this.state.loading && <Preloader/> }
                <div className="row project-subsection--head subsection--head">
                    <div className="col-9">
                        <div className="timesheet-entries-summary">
                            <h1>Timesheet entries</h1>
                            <span className="total-hours">{this.state.totalHours} hours</span>
                            {this.state.userRole == 2 && this.props.resources.length > 0 &&
                            <div className="filters">
                                <div className="filter">
                                    <div className="filter--label">
                                        <i className="la la-male"></i>
                                        Devs:
                                    </div>
                                    <div className="filter--dd">
                                        <UncontrolledDropdown>
                                            <DropdownToggle nav>
                                                <div>
                                                    { this.state.filtersDevId === -1 ? 'ALL' : this.props.resources[this.state.filtersDevId].user_name }
                                                    <i className="la la-angle-down"></i>
                                                </div>
                                            </DropdownToggle>
                                            <DropdownMenu>
                                                <div key={'idg-dev-all'} onClick={() => this.filtersChanged("filtersDevId", -1)}>
                                                    <DropdownItem>ALL</DropdownItem>
                                                </div>
                                                {
                                                    this.props.resources.map((dev, index) => {
                                                        return (
                                                          <div key={'idg-dev-' + index} onClick={() => this.filtersChanged("filtersDevId", index)}>
                                                              <DropdownItem>{dev.user_name}</DropdownItem>
                                                          </div>
                                                        )
                                                    })
                                                }
                                            </DropdownMenu>
                                        </UncontrolledDropdown>
                                    </div>
                                </div>
                                <div className="filter">
                                    <div className="filter--label">
                                        <i className="la la-address-card"></i>
                                        Activity:
                                    </div>
                                    <div className="filter--dd">
                                        <UncontrolledDropdown>
                                            <DropdownToggle nav>
                                                <div>
                                                    { this.state.filtersActivityId === -1 ? 'ALL' : this.state.ts_by_activity[this.state.filtersActivityId].activity_name }
                                                    <i className="la la-angle-down"></i>
                                                </div>
                                            </DropdownToggle>
                                            <DropdownMenu>
                                                <div key={'idg-dev-all'} onClick={() => this.filtersChanged("filtersActivityId", -1)}>
                                                    <DropdownItem>ALL</DropdownItem>
                                                </div>
                                                {
                                                    this.state.ts_by_activity.map((activity, index) => {
                                                        return (
                                                          <div key={'idg-activity-' + index} onClick={() => this.filtersChanged("filtersActivityId", index)}>
                                                              <DropdownItem>{activity.activity_name}</DropdownItem>
                                                          </div>
                                                        )
                                                    })
                                                }
                                            </DropdownMenu>
                                        </UncontrolledDropdown>
                                    </div>
                                </div>
                                <div className="filter">
                                    <div className="filter--label">
                                        <i className="la la-line-chart"></i>
                                        Start date:
                                    </div>
                                    <div className="filter--dd xl">
                                        <DatePicker
                                            selected={this.state.filtersStartDate}
                                            onChange={(value) => this.filtersChanged("filtersStartDate", value)}
                                            className="start-date-col timesheet-date"
                                        />
                                    </div>
                                </div>
                                <div className="filter">
                                    <div className="filter--label">
                                        <i className="la la-line-chart"></i>
                                        End date:
                                    </div>
                                    <div className="filter--dd xl">
                                        <DatePicker
                                            selected={this.state.filtersEndDate}
                                            onChange={(value) => this.filtersChanged("filtersEndDate", value)}
                                            minDate={this.state.filtersStartDate}
                                            className="start-date-col timesheet-date"
                                        />
                                    </div>
                                </div>
                            </div>
                            }
                        </div>
                    </div>
                    <div className="col-2 ta-right">
                        <UncontrolledDropdown>
                            { (this.state.userRole == 2 || (this.state.userRole == 1 && ![3, 5].includes(this.props.projectStatus))) &&
                            <DropdownToggle nav>
                                <i className="la la-plus-circle"></i>
                            </DropdownToggle>
                            }
                            <DropdownMenu right>
                                {/*Project should not be closed or cancel*/}
                                {this.state.userRole == 1 && ![3, 5].includes(this.props.projectStatus) &&
                                    <DropdownItem onClick={(e) => { this.openModal() }} key={'te-1'}>New entry</DropdownItem>
                                }
                                {(this.state.userRole == 2 || this.props.teamleadView) &&
                                    <DropdownItem key={'te-2'} className="export-csv">
                                        <CSVLink className="link-download" data={csvData} filename={"timesheet_"+this.props.projectId+".csv"}>
                                            Export CSV
                                        </CSVLink>
                                    </DropdownItem>
                                }

                                {this.state.showAddTimesheet && ![3, 5].includes(this.props.projectStatus) &&
                                    <AddTimesheet
                                        teId={this.state.editTEId != -1 ? this.state.editTEId : ''}
                                        projectId={this.props.projectId}
                                        projectMjn={this.props.projectMjn}
                                        closeModal={this.closeModal}
                                        showPopup={this.state.showAddTimesheet}
                                    />
                                }
                            </DropdownMenu>
                        </UncontrolledDropdown>
                    </div>
                </div>

                <div className="project-subsection--body">
                {
                    this.state.tsEntries.map((item, index) => {
                        return (
                            <TableRow
                                key = {'row-' + index}
                                tableCols = {
                                    [
                                        {
                                            value: this.state.userRole == 2 || this.props.teamleadView ? item.user : '',
                                            label: this.state.userRole == 2 || this.props.teamleadView ? 'User' : ''
                                        },
                                        {
                                            value: item.manual_date,
                                            dateFormat: 'DD.MM.YYYY', //momentjs formats
                                            label: 'Date'
                                        },
                                        {
                                            value: item.hours,
                                            label: 'Hours'
                                        },
                                        {
                                            value: item.activity_name,
                                            label: 'Activity',
                                            customCssClass: 'row-details'
                                        },
                                        {
                                            value: item.extra_request,
                                            label: 'Extra',
                                            showAsCheckbox: true
                                        },
                                        {
                                            value: item.qa_issue,
                                            label: 'QA',
                                            showAsCheckbox: true
                                        },
                                        {
                                            value: item.overtime,
                                            label: 'OT',
                                            showAsCheckbox: true
                                        },
                                        {
                                            value: item.is_approved,
                                            label: item.is_approved ? 'approved' : "not approved",
                                            showAsCheckbox: true,
                                            tooltip: item.approve_feedback
                                        },
                                        {
                                            value: item.details,
                                            label: 'Details',
                                            customCssClass: 'row-details-3'
                                        },
                                        {
                                            value: item.mjn,
                                            label: 'MJN',
                                            customCssClass: 'row-details-3'
                                        },
                                        this.state.userRole == 1 && ![3, 5].includes(this.props.projectStatus) && item.editable ? {
                                            ddOptions: [
                                                {
                                                    value: 'Edit entry',
                                                    action: {
                                                        name: 'editTSEntry',
                                                        params: item.id
                                                    }
                                                },
                                                {
                                                    value: 'Remove entry',
                                                    action: {
                                                        name: 'deleteTSEntry',
                                                        params: item.id
                                                    }
                                                }
                                            ]
                                        } : {}
                                    ]
                                }
                                editTSEntry={this.editTSEntry}
                                deleteTSEntry={this.deleteTSEntry}
                            />
                        )
                    })
                }
                </div>
            </div>
        );
    }
}

TimesheetEntries.contextType = AppContext;
export default withCookies(TimesheetEntries);
